import { Box, Grid, TextField } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useState } from "react";

export default function GeneralData(props) {
  const [generalData, setGeneralData] = useState(props.generalData);

  const sexs = ["Masculino", "Femenino", "Otro"];

  const listStates = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
  ];

  const listMaritalStatus = [
    "Soltero",
    "Casado",
    "Divorciado",
    "Viudo",
    "Unión libre",
  ];

  const listAnswer = ["Si", "No"];

  const handleChangeInput = (event) => {
    let generalDataAux = { ...generalData };
    if (event.target.name === "birthDate") {
      generalDataAux["age"] = calculateAge(new Date(event.target.value));
    }

    generalDataAux[event.target.name] = event.target.value;
    setGeneralData(generalDataAux);
    props.handleChange(generalDataAux);
  };

  const calculateAge = (birthday) => {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Nombre(s)"
            fullWidth
            name="name"
            value={generalData["name"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Apellido Paterno"
            fullWidth
            name="lastName"
            value={generalData["lastName"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Apellido Materno"
            fullWidth
            name="secondLastName"
            value={generalData["secondLastName"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="CURP"
            fullWidth
            name="curp"
            value={generalData["curp"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="sex">Sexo</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={generalData["sex"]}
              name="sex"
              id="sex"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {sexs.map((option, index) => (
                <option key={"sex" + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Fecha de nacimiento"
            fullWidth
            type={"date"}
            name="birthDate"
            InputLabelProps={{ shrink: true }}
            value={generalData["birthDate"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Edad"
            fullWidth
            type={"number"}
            name="age"
            disabled
            InputLabelProps={{ shrink: true }}
            value={generalData["age"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Lugar de nacimiento"
            fullWidth
            name="birthPlace"
            value={generalData["birthPlace"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="birthState">Estado de nacimiento</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={generalData["birthState"]}
              name="birthState"
              id="birthState"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listStates.map((option, index) => (
                <option key={"listStates" + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Nacionalidad"
            fullWidth
            name="citizenship"
            value={generalData["citizenship"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="maritalStatus">Estado marital</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={generalData["maritalStatus"]}
              name="maritalStatus"
              id="maritalStatus"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listMaritalStatus.map((option, index) => (
                <option key={"maritalStatus" + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="speakAnyIndigenouslanguage">
              ¿Tiene alguna discapacidad?
            </InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={generalData["speakAnyIndigenouslanguage"]}
              name="speakAnyIndigenouslanguage"
              id="speakAnyIndigenouslanguage"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listAnswer.map((option, index) => (
                <option
                  key={"speakAnyIndigenouslanguage" + index}
                  value={option}
                >
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="haveADisability">
              ¿Habla alguna lengua indígena?
            </InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={generalData["haveADisability"]}
              name="haveADisability"
              id="haveADisability"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listAnswer.map((option, index) => (
                <option key={"haveADisability" + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
