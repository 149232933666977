import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { storage } from "./../../utils/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function FilesData(props) {
  const [filesData, setFilesData] = useState(props.filesData);
  const [typeSelected, setTypeSelected] = useState("");
  const inputRef = useRef(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [dense, setDense] = React.useState(false);
  const uniqueId = uuidv4();
  const listAvailableFiles = [
    {
      name: "ine",
      label: "INE",
    },
    {
      name: "curp",
      label: "CURP",
    },
    {
      name: "birthCertificate",
      label: "Acta de nacimiento",
    },
    {
      name: "proofOfAddress",
      label: "Comprobante de domicilio",
    },
    {
      name: "highSchoolCertificate",
      label: "Certificado de estudios (último grado)",
    },
  ];

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    console.log(e);
    console.log(e.target.files[0]);
    const file = e.target.files && e.target.files[0];

    if (!file) return;

    const storageRef = ref(
      storage,
      `files/inscriptions/${uniqueId}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    setShowProgress(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        setShowProgress(false);
        alert(error);
        e.target.value = null;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let filesDataAux = { ...filesData };
          filesDataAux[typeSelected] = downloadURL;
          setFilesData(filesDataAux);
          props.handleChange(filesDataAux);
          console.log("File available at", downloadURL);
          setShowProgress(false);
          e.target.value = null;
        });
      }
    );

    // 👇️ reset file input
  };

  const handleDeleteElement = (name) => {
    let filesDataAux = { ...filesData };
    filesDataAux[name] = "";
    setFilesData(filesDataAux);
    props.handleChange(filesDataAux);
  };

  const handleViewFile = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={9}>
          <FormControl fullWidth>
            <InputLabel id="typeSelected">Tipo de archivo </InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={typeSelected}
              name="typeSelected"
              id="typeSelected"
              onChange={(e) => {
                setTypeSelected(e.target.value);
              }}
            >
              <option value="" disabled></option>
              {listAvailableFiles.map((option, index) => (
                <option key={"listAvailableFiles" + index} value={option.name}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
          />
        </Grid>

        <Grid item xs={3}>
          <Button variant="contained" onClick={handleClick}>
            Adjuntar documento
          </Button>
        </Grid>

        <Grid item xs={12}>
          {showProgress ? (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress variant="determinate" value={progresspercent} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{`${Math.round(progresspercent)}%`}</Typography>
              </Box>
            </Box>
          ) : null}
        </Grid>

        <Grid item xs={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Archivos adjuntos
          </Typography>
          <Demo>
            <List dense={dense}>
              {listAvailableFiles.map((option, index) => {
                if (filesData[option.name] !== "") {
                  return (
                    <ListItem
                      key={"listAvailableFiles" + index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteElement(option.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar
                        onClick={() => handleViewFile(filesData[option.name])}
                      >
                        <Avatar>
                          <InsertDriveFileIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={option.label}
                        onClick={() => handleViewFile(filesData[option.name])}
                      />
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
}
