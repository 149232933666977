import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useState } from "react";
import CustomMask from "../FieldMasks";
import { useEffect } from "react";

export default function TutorData(props) {
  const [studyPlan, setStudyPlan] = useState(props.studyPlan);
  const [tutorData, setTutorData] = useState(props.tutorData);

  useEffect(() => {}, [props.studyPlan]);

  const listStates = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
  ];
  const relationships = [
    "Padre",
    "Madre",
    "Hermano",
    "Hermana",
    "Abuelo",
    "Abuela",
    "Tío",
    "Tía",
    "Primo",
    "Prima",
    "Otro",
  ];

  const handleChangeInput = (event) => {
    let tutorDataAux = { ...tutorData };
    tutorDataAux[event.target.name] = event.target.value;
    setTutorData(tutorDataAux);
    props.handleChange(tutorDataAux);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!studyPlan.name.includes("Posgrado") ? (
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Nombre(s)"
              fullWidth
              name="name"
              value={tutorData["name"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Apellido Paterno"
              fullWidth
              name="lastName"
              value={tutorData["lastName"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Apellido Materno"
              fullWidth
              name="secondLastName"
              value={tutorData["secondLastName"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="relationship">Parentesco</InputLabel>
              <Select
                native
                fullWidth
                displayEmpty
                size="small"
                label={"fullWidth"}
                value={tutorData["relationship"]}
                name="relationship"
                id="relationship"
                onChange={(e) => {
                  handleChangeInput(e);
                }}
              >
                <option value="" disabled></option>
                {relationships.map((option, index) => (
                  <option key={"relationship" + index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Número de teléfono (celular)"
              fullWidth
              name="phone"
              type={"tel"}
              value={tutorData["phone"]}
              onChange={(e) => handleChangeInput(e)}
              InputProps={{
                inputComponent: CustomMask.TextMaskCustomPhone,
                inputProps: {
                  component: CustomMask.TextMaskCustomPhone,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Número de teléfono (casa)"
              fullWidth
              name="homePhone"
              type={"tel"}
              value={tutorData["homePhone"]}
              onChange={(e) => handleChangeInput(e)}
              InputProps={{
                inputComponent: CustomMask.TextMaskCustomPhone,
                inputProps: {
                  component: CustomMask.TextMaskCustomPhone,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
              Dirección
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Calle"
              fullWidth
              name="direction"
              value={tutorData["direction"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Colonia"
              fullWidth
              name="colony"
              value={tutorData["colony"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Ciudad"
              fullWidth
              name="city"
              value={tutorData["city"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="state">Estado </InputLabel>
              <Select
                native
                fullWidth
                displayEmpty
                size="small"
                label={"fullWidth"}
                value={tutorData["state"]}
                name="state"
                id="state"
                onChange={(e) => {
                  handleChangeInput(e);
                }}
              >
                <option value="" disabled></option>
                {listStates.map((option, index) => (
                  <option key={"listStates" + index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Código postal"
              fullWidth
              name="postalCode"
              value={tutorData["postalCode"]}
              onChange={(e) => handleChangeInput(e)}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} mt={2}>
          <Typography variant="h6" gutterBottom component="div" align="center">
            No aplica
          </Typography>
        </Grid>
      )}
    </Box>
  );
}
