import { Box, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';

export default function AcademicData(props) {
  const [academicData, setAcademicData] = useState(props.academicData);

  const listLevels = ['Secundaria', 'Bachillerato', 'Universidad', 'Posgrado'];
  const listStates = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Coahuila',
    'Colima',
    'Durango',
    'Estado de México',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas',
  ];
  const listAnswer = ['Si', 'No'];

  const handleChangeInput = (event) => {
    let academicDataAux = { ...academicData };
    academicDataAux[event.target.name] = event.target.value;
    setAcademicData(academicDataAux);
    props.handleChange(academicDataAux);
  };

  /*
    : "",
    : "",
    : "",
  */
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Nombre de la escuela de procedencia"
            fullWidth
            name="school"
            value={academicData['school']}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="level">Nivel educativo</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={'fullWidth'}
              value={academicData['level']}
              name="level"
              id="level"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listLevels.map((option, index) => (
                <option key={'listLevels' + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Dirección de la escuela"
            fullWidth
            name="location"
            value={academicData['location']}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="País"
            fullWidth
            name="country"
            value={academicData['country']}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="state">Estado </InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={'fullWidth'}
              value={academicData['state']}
              name="state"
              id="state"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listStates.map((option, index) => (
                <option key={'listStates' + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Ciudad"
            fullWidth
            name="city"
            value={academicData['city']}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="IncompleteStudies">
              ¿Tiene estudios incompletos?
            </InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={'fullWidth'}
              value={academicData['IncompleteStudies']}
              name="IncompleteStudies"
              id="IncompleteStudies"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listAnswer.map((option, index) => (
                <option key={'IncompleteStudies' + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {academicData['IncompleteStudies'] === 'Si' ? (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="processEquivalence">
                ¿Desea tramitar equivalencia?
              </InputLabel>
              <Select
                native
                fullWidth
                displayEmpty
                size="small"
                label={'fullWidth'}
                value={academicData['processEquivalence']}
                name="processEquivalence"
                id="processEquivalence"
                onChange={(e) => {
                  handleChangeInput(e);
                }}
              >
                <option value="" disabled></option>
                {listAnswer.map((option, index) => (
                  <option key={'processEquivalence' + index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
