import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GeneralData from '../components/home/GeneralData';
import Nav from '../components/navigation/Nav';
import Footer from '../components/Footer';
import StudyPlan from '../components/home/StudyPlan';
import ContactData from '../components/home/ContactData';
import LaboralData from '../components/home/LaboralData';
import AcademicData from '../components/home/AcademicData';
import TutorData from '../components/home/TutorData';
import FilesData from '../components/home/FilesData';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import axios from 'axios';

const steps = [
  'Plan de estudios',
  'Datos generales',
  'Datos de contacto',
  'Datos academicos',
  'Datos laborales',
  'Datos del tutor',
  'Archivos',
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#004999',
    },
    secondary: {
      main: '#FFBA08',
    },
  },
});

export default function Home() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [studyPlan, setStudyPlan] = React.useState({
    name: '',
    modality: '',
    site: '',
  });
  const [generalData, setGeneralData] = React.useState({
    name: '',
    lastName: '',
    secondLastName: '',
    curp: '',
    sex: '',
    birthDate: '',
    birthPlace: '',
    birthState: '',
    age: '',
    maritalStatus: '',
    citizenship: '',
    speakAnyIndigenouslanguage: '',
    haveADisability: '',
  });
  const [contactData, setContactData] = React.useState({
    email: '',
    phone: '',
    housePhone: '',
    street: '',
    colony: '',
    municipality: '',
    locality: '',
    state: '',
    postalCode: '',
  });
  const [academicData, setAcademicData] = React.useState({
    school: '',
    level: '',
    location: '',
    country: '',
    state: '',
    city: '',
    IncompleteStudies: '',
    processEquivalence: '',
  });
  const [laboralData, setLaboralData] = React.useState({
    work: '',
    nameInstitution: '',
    activity: '',
    direction: '',
    colony: '',
    state: '',
    city: '',
    phone: '',
    email: '',
  });
  const [tutorData, setTutorData] = React.useState({
    name: '',
    lastName: '',
    secondLastName: '',
    relationship: '',
    phone: '',
    homePhone: '',
    direction: '',
    colony: '',
    city: '',
    state: '',
    postalCode: '',
  });
  const [filesData, setFilesData] = React.useState({
    ine: '',
    curp: '',
    birthCertificate: '',
    proofOfAddress: '',
    highSchoolCertificate: '',
  });
  const [nextDisabled, setNextDisabled] = React.useState(true);

  useEffect(() => {
    handleDisbledButtonNext();
  }, [
    studyPlan,
    generalData,
    contactData,
    academicData,
    laboralData,
    tutorData,
    filesData,
  ]);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (validateData()) {
        handleSendData();
      }
    } else {
      setActiveStep(activeStep + 1);
      switch (activeStep) {
        case 0:
          checkStudyPlan();
          break;
        case 1:
          checkGeneralData();
          break;
        case 2:
          checkAcademicData();
          break;
        case 3:
          checkLaboralData();
          break;
        case 4:
          checkTutorData();
          break;
        case 5:
          break;
        default:
          break;
      }
    }
  };

  const handleSendData = () => {
    const data = {
      studyPlan: studyPlan,
      generalData: generalData,
      contactData: contactData,
      academicData: academicData,
      laboralData: laboralData,
      tutorData: tutorData,
      filesData: filesData,
    };
    axios
      .post(
        'https://wacademy.institutowilliams.edu.mx/api/v1/inscriptions',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          //console.log(res);
          alert('Datos enviados correctamente');
          window.location.href = 'https://institutowilliams.edu.mx/';
        } else {
          alert('Error al enviar los datos');
        }
      })
      .catch((err) => {
        //console.log(err);
        alert('Error al enviar los datos');
      });
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    handleDisbledButtonNext();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    handleDisbledButtonNext();
  };

  const handleChangeStudyPlan = (event) => {
    setStudyPlan(event);
  };

  const handleChangeGeneralData = (event) => {
    setGeneralData(event);
  };

  const handleChangeContactData = (event) => {
    setContactData(event);
  };

  const handleChangeAcademicData = (event) => {
    setAcademicData(event);
  };

  const handleChangeLaboralData = (event) => {
    setLaboralData(event);
  };

  const handleChangeTutorData = (event) => {
    setTutorData(event);
  };

  const handleChangeFilesData = (event) => {
    setFilesData(event);
  };

  const handleDisbledButtonNext = () => {
    //console.log('ActiveStep');
    //console.log(activeStep);
    if (activeStep === 0) {
      //console.log(checkStudyPlan());
      if (checkStudyPlan() === true) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (activeStep === 1) {
      //console.log(checkGeneralData());
      if (checkGeneralData() === true) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (activeStep === 2) {
      //console.log(checkContactData());
      if (checkContactData() === true) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (activeStep === 3) {
      //console.log(checkAcademicData());
      if (checkAcademicData() === true) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (activeStep === 4) {
      //console.log(checkLaboralData());
      if (checkLaboralData() === true) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (activeStep === 5) {
      //console.log(checkTutorData());
      if (checkTutorData() === true) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    }
  };

  const validateData = () => {
    let validation = true;
    if (!checkStudyPlan()) {
      validation = false;
    }

    if (!checkGeneralData()) {
      validation = false;
    }

    if (!checkContactData()) {
      validation = false;
    }

    if (!checkAcademicData()) {
      validation = false;
    }

    if (!checkLaboralData()) {
      validation = false;
    }

    if (!checkTutorData()) {
      validation = false;
    }

    return validation;
  };

  const checkStudyPlan = () => {
    let validation = true;
    if (studyPlan['name'] === '') {
      validation = false;
    }

    if (studyPlan['modality'] === '') {
      validation = false;
    }

    if (studyPlan['site'] === '') {
      validation = false;
    }

    return validation;
  };

  const checkGeneralData = () => {
    let validation = true;
    if (generalData['name'] === '') {
      validation = false;
    }

    if (generalData['lastName'] === '') {
      validation = false;
    }

    if (generalData['secondLastName'] === '') {
      validation = false;
    }

    if (generalData['curp'] === '') {
      validation = false;
    }

    if (generalData['sex'] === '') {
      validation = false;
    }

    if (generalData['birthDate'] === '') {
      validation = false;
    }

    if (generalData['birthPlace'] === '') {
      validation = false;
    }

    if (generalData['birthState'] === '') {
      validation = false;
    }

    if (generalData['age'] === '') {
      validation = false;
    }

    if (generalData['maritalStatus'] === '') {
      validation = false;
    }

    if (generalData['citizenship'] === '') {
      validation = false;
    }

    if (generalData['speakAnyIndigenouslanguage'] === '') {
      validation = false;
    }
    if (generalData['haveADisability'] === '') {
      validation = false;
    }
    return validation;
  };

  const checkContactData = () => {
    let validation = true;
    if (contactData['street'] === '') {
      validation = false;
    }

    if (contactData['municipality'] === '') {
      validation = false;
    }

    if (contactData['colony'] === '') {
      validation = false;
    }

    if (contactData['city'] === '') {
      validation = false;
    }

    if (contactData['state'] === '') {
      validation = false;
    }

    if (contactData['postalCode'] === '') {
      validation = false;
    }

    if (contactData['phone'] === '') {
      validation = false;
    }

    if (contactData['housePhone'] === '') {
      validation = false;
    }

    if (contactData['email'] === '') {
      validation = false;
    }

    if (contactData['locality'] === '') {
      validation = false;
    }

    return validation;
  };

  const checkAcademicData = () => {
    let validation = true;
    if (academicData['school'] === '') {
      validation = false;
    }

    if (academicData['level'] === '') {
      validation = false;
    }

    if (academicData['location'] === '') {
      validation = false;
    }

    if (academicData['country'] === '') {
      validation = false;
    }

    if (academicData['state'] === '') {
      validation = false;
    }

    if (academicData['city'] === '') {
      validation = false;
    }

    if (academicData['IncompleteStudies'] === '') {
      validation = false;
    }

    if (academicData['IncompleteStudies'] === 'Si') {
      if (academicData['processEquivalence'] === '') {
        validation = false;
      }
    }

    return validation;
  };

  const checkLaboralData = () => {
    let validation = true;

    if (laboralData['work'] === 'Si') {
      if (laboralData['nameInstitution'] === '') {
        validation = false;
      }

      if (laboralData['activity'] === '') {
        validation = false;
      }

      if (laboralData['direction'] === '') {
        validation = false;
      }

      if (laboralData['colony'] === '') {
        validation = false;
      }

      if (laboralData['state'] === '') {
        validation = false;
      }

      if (laboralData['city'] === '') {
        validation = false;
      }

      if (laboralData['phone'] === '') {
        validation = false;
      }

      if (laboralData['email'] === '') {
        validation = false;
      }
    }

    return validation;
  };

  const checkTutorData = () => {
    let validation = true;
    if (!studyPlan.name.includes('Posgrado')) {
      if (tutorData['name'] === '') {
        validation = false;
      }

      if (tutorData['lastName'] === '') {
        validation = false;
      }

      if (tutorData['secondLastName'] === '') {
        validation = false;
      }

      if (tutorData['relationship'] === '') {
        validation = false;
      }

      if (tutorData['phone'] === '') {
        validation = false;
      }

      if (tutorData['homePhone'] === '') {
        validation = false;
      }

      if (tutorData['direction'] === '') {
        validation = false;
      }

      if (tutorData['colony'] === '') {
        validation = false;
      }

      if (tutorData['city'] === '') {
        validation = false;
      }

      if (tutorData['state'] === '') {
        validation = false;
      }

      if (tutorData['postalCode'] === '') {
        validation = false;
      }
    }

    return validation;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Nav />
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Inscripciones IWK
          </Typography>
          <Stepper
            activeStep={activeStep}
            orientation={isMobile ? 'vertical' : 'horizontal'}
          >
            {steps.map((label, index) => (
              <Step key={label} onClick={handleStep(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Tu solicitud fue enviada con éxito.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <StudyPlan
                  studyPlan={studyPlan}
                  handleChange={handleChangeStudyPlan}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}
              {activeStep === 1 ? (
                <GeneralData
                  generalData={generalData}
                  handleChange={handleChangeGeneralData}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}
              {activeStep === 2 ? (
                <ContactData
                  contactData={contactData}
                  handleChange={handleChangeContactData}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}
              {activeStep === 3 ? (
                <AcademicData
                  academicData={academicData}
                  handleChange={handleChangeAcademicData}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}
              {activeStep === 4 ? (
                <LaboralData
                  laboralData={laboralData}
                  handleChange={handleChangeLaboralData}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}
              {activeStep === 5 ? (
                <TutorData
                  studyPlan={studyPlan}
                  tutorData={tutorData}
                  handleChange={handleChangeTutorData}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}
              {activeStep === 6 ? (
                <FilesData
                  filesData={filesData}
                  handleChange={handleChangeFilesData}
                  handleDisbledButtonNext={handleDisbledButtonNext}
                />
              ) : null}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Atrás
                  </Button>
                )}

                <Button
                  variant="contained"
                  disabled={nextDisabled}
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1
                    ? 'Enviar solicitud'
                    : 'Siguiente'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
