import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import CustomMask from "../FieldMasks";

export default function ContactData(props) {
  const [contactData, setContactData] = useState(props.contactData);

  const listStates = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
  ];

  const handleChangeInput = (event) => {
    let contactDataAux = { ...contactData };
    contactDataAux[event.target.name] = event.target.value;
    setContactData(contactDataAux);
    props.handleChange(contactDataAux);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Correo electrónico"
            fullWidth
            name="email"
            type={"email"}
            value={contactData["email"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Número de teléfono (celular)"
            fullWidth
            name="phone"
            type={"tel"}
            value={contactData["phone"]}
            onChange={(e) => handleChangeInput(e)}
            InputProps={{
              inputComponent: CustomMask.TextMaskCustomPhone,
              inputProps: {
                component: CustomMask.TextMaskCustomPhone,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Número de teléfono (casa)"
            fullWidth
            name="housePhone"
            type={"tel"}
            value={contactData["housePhone"]}
            onChange={(e) => handleChangeInput(e)}
            InputProps={{
              inputComponent: CustomMask.TextMaskCustomPhone,
              inputProps: {
                component: CustomMask.TextMaskCustomPhone,
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom component="div">
            Dirección
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Calle"
            fullWidth
            name="street"
            value={contactData["street"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Colonia"
            fullWidth
            name="colony"
            value={contactData["colony"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Municipio"
            fullWidth
            name="municipality"
            value={contactData["municipality"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Localidad"
            fullWidth
            name="locality"
            value={contactData["locality"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="state">Estado </InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={contactData["state"]}
              name="state"
              id="state"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listStates.map((option, index) => (
                <option key={"listStates" + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            label="Código postal"
            fullWidth
            name="postalCode"
            value={contactData["postalCode"]}
            onChange={(e) => handleChangeInput(e)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
